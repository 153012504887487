// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tx-portal-primary: mat.define-palette(mat.$indigo-palette);
$tx-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tx-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tx-portal-theme: mat.define-dark-theme((
  color: (
    primary: $tx-portal-primary,
    accent: $tx-portal-accent,
    warn: $tx-portal-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tx-portal-theme);

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'prismjs/themes/prism.min.css';

html {
  @apply bg-gray-900;
  @apply text-gray-100;
}

// dark scrollbar
::-webkit-scrollbar { @apply w-2.5; @apply h-2.5; } // — the entire scrollbar.
::-webkit-scrollbar-thumb { @apply bg-gray-700; @apply transition; } // — the draggable scrolling handle.
::-webkit-scrollbar-thumb:hover { @apply bg-gray-600; }
::-webkit-scrollbar-track-piece { @apply bg-gray-800; } // — the part of the track (progress bar) not covered by the handle.
::-webkit-scrollbar-corner { @apply bg-gray-900; } // — the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet. This is often the bottom-right corner of the browser window.
// ::-webkit-scrollbar-button {} // — the buttons on the scrollbar (arrows pointing upwards and downwards that scroll one line at a time).
// ::-webkit-scrollbar-track {} // — the track (progress bar) of the scrollbar, where there is a gray bar on top of a white bar.
// ::-webkit-resizer — the draggable resizing handle that appears at the bottom corner of some elements.

*,
::before,
::after {
  --tw-ring-offset-width: none;
}

a,
button,
input {
  @apply transition-all;
  @apply ease-in-out;
  @apply duration-200;
  @apply outline-none;
}

.link {
  @apply text-goblin-500;
  @apply hover:text-goblin-600;
  @apply hover:underline;
}

.list-check {
  li::before {
      content: '✓';
      @apply mr-2;
      @apply text-goblin-500;
  }
}

a:focus,
button:focus,
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus,
input:focus~.checkbox {
  @apply ring;
  @apply ring-blue-400;
}


// restyle material
.view {
  .mat-mdc-table {
    @apply bg-transparent;
    @apply w-full;
    @apply relative;
    @apply border-b-0;
    @apply overflow-x-scroll;
    .mat-mdc-header-cell, .mat-mdc-cell {
      @apply px-4;
    }
    &::after {
      content: "";
      @apply pointer-events-none;
      @apply absolute;
      @apply inset-0;
      @apply border;
      @apply border-white/10;
      @apply rounded-lg;
    }
    tbody > tr:last-child > td {
      @apply border-b-0;
    }
  }

  table td, table th {
    @apply whitespace-nowrap;
  }

  .table-wrapper {
    @apply w-full;
    @apply overflow-x-scroll;
    &::-webkit-scrollbar { @apply h-5; @apply rounded; } // — the entire scrollbar.
    &::-webkit-scrollbar-thumb {
        border: solid 8px transparent;
        @apply bg-transparent;
        box-shadow: inset 0 0 10px 10px #a855f7;
        @apply rounded;
      }
    &::-webkit-scrollbar-thumb:hover {
      @apply bg-transparent;
      box-shadow: inset 0 0 10px 10px #c084fc;
    }
    &::-webkit-scrollbar-track-piece {
      @apply rounded;
      @apply bg-transparent;
      box-shadow: inset 0 0 10px 10px #404040;
      border: solid 8px transparent;
    }
  }

  .mat-mdc-paginator {
    @apply bg-transparent;
  }

}